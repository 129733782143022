import React from 'react';
import './ImageButton.css';

export interface ImageButtonProp  {
    image: string;
    onClick?: () => void;
}
export const ImageButton: React.FC<ImageButtonProp> = ({image, onClick}: ImageButtonProp) => {
    return ( 
        <button className='ImageButton' onClick={onClick}>
            <img src={image} alt='' style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}/> 
        </button>
    );
}