import React, { useEffect, useContext } from 'react';
import './App.css';
import { Coordinator } from './UI/Coordinator';
import { SizeContext } from './context';
import {SCORM} from 'pipwerks-scorm-api-wrapper';

export const App: React.FC = ({}) => {

  const context = useContext(SizeContext);
  
  useEffect(()=> {
    getSize();
    window.addEventListener('resize', handleResize)

    SCORM.init();
    var name = SCORM.get('cmi.core.student_name');
    console.log("name " + name);
    
  },[])

  const handleResize = () => {
    getSize();
  }

  const getSize = () => {
    var ratio = 16.0 / 9.0;
    var windowHeight = window.innerHeight; 
    var windowWidth = window.innerWidth - 2;// a small margin

    var webWidth = windowWidth;
    var webHeight = windowWidth / ratio;

    if (webHeight > windowHeight) {
        webHeight = windowHeight - 2;
        webWidth = webHeight * ratio;
    }

    context.width = webWidth;
    context.height = webHeight
  
    var element = document.getElementById("app");
    if (element) {
      element.style.width = webWidth + "px"; 
      element.style.height = webHeight + "px"; 
    }
  }

  return (
    <div className="App" id="app">
      <Coordinator/>
    </div>
  );
}

export default App;
