import React, { useEffect, useState, useContext } from 'react';
import {LezantaInterface} from "./SceneInterfaces";
import { useSpring, animated } from 'react-spring';
import './Lezanta.css';
import { ImageTextButton } from '../Components/ImageTextButton';
import { SizeContext } from '../../context';
import { Sex } from '../SexEnums';

interface LezantaProp {
    item: LezantaInterface;
    characterName: string;
    characterSex: Sex;
}

export const Lezanta: React.FC<LezantaProp> = ({item, characterName, characterSex
}:LezantaProp) => {
    const [lezantaWidth, setLezantaWidth] = useState('700px');
    const [lezantaHeight, setLezantaHeight] = useState('175px');
    const [text, setText] = useState('');

    const context = useContext(SizeContext);

    useEffect(() => {
        var windowHeight = context.height;
        var windowWidth = context.width;

        var widthLimit = 1090 ;
        var heightLimit = widthLimit * (9 / 16);

        var _height = 125 * windowHeight / heightLimit;
        var _width = 500 * windowWidth / widthLimit;

        setLezantaHeight(_height + 'px');
        setLezantaWidth(_width +'px');

        if (!item.oArticle && !item.toyArticle && !item.oTouArticle) {
            setText(item.text.replace("@@", characterName))
        } else {
            if (item.oArticle) {
                var article = "Ο";
                
                if (characterSex == Sex.female) {
                    article = "Η";
                }

                setText(item.text.replace("@@", article + " " + characterName))

            } else if (item.toyArticle){
                var article = "ΤΟΥ ΗΡΩΑ";
                
                if (characterSex == Sex.female) {
                    article = "ΤΗΣ ΗΡΩΪΔΑΣ";
                }

                setText(item.text.replace("@@", article))
            } else if (item.oTouArticle) {
                var articleTou = "ΤΟΥ";
                var article = "Ο";

                if (characterSex == Sex.female) {
                    articleTou = "ΤΗΣ";
                    article = "Η";
                }

                var _text = item.text.replace("@@@", article + " " + characterName)
                setText(_text.replace("@@", articleTou))
            }
        }

        
    },[item])

    const styles = useSpring({
        to: {top: 50 },
        from: {top: -100}
    })

    return (
        <animated.div className='LezantaOuterBox' style={styles}>
            <div className='LezantaInnerBox' style={{width: lezantaWidth, height: lezantaHeight}}>
                <ImageTextButton
                    image={'images/lezanta.png'}
                    msg={text}
                    onClick={()=>{}}
                />
            </div>
        </animated.div>
    );
}