import React, { useEffect, useState, useContext } from 'react';
import { ExitButton } from './Components/ExitButton';
import { Hero } from './Components/Hero';
import { BackButton } from './Components/BackButton';
import ReactPlayer from 'react-player'
import { ImageButton } from './Components/ImageButton';
import { SizeContext } from '../context';
import './Character.css';
import { Sex } from './SexEnums';

interface CharacterProps{
    onExit: () => void;
    onBack: () => void;
    onNext: (image: string, sex: Sex, video: string) => void;
}

export const Character: React.FC<CharacterProps> = ({onExit, onBack, onNext}: CharacterProps) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [topTitle, setTopTitle] = useState('100px');
    const [topCharacters, setTopCharacters] = useState('140px');
    const [topPlay, setTopPlay] = useState('300px');
    const [middleOpacity, setMiddleOpacity] = useState(0.3);
    const [enableButton, setEnableButton] = useState(false);

    const context = useContext(SizeContext);

    useEffect(()=> {
        var windowHeight = context.height;
        var _top = 200 * windowHeight / 1040;
        setTopTitle(_top + 'px');

        _top = 395 * windowHeight / 1080;
        _top -= 95;
        setTopCharacters(_top + 'px');

        _top = 905 * windowHeight / 1080;
        _top -= (95 + 300);
        setTopPlay(_top + 'px');
        
      },[])

    const names = ['ΑΛΕΚΟΣ', 'ΧΡΥΣΑ', 'ΚΩΣΤΑΣ', 'ΜΑΡΙΑ'];
    const images = ['images/heroe1.png', 'images/heroe2.png', 'images/heroe3.png', 'images/heroe4.png'];
    const videos = ['heroe1', 'heroe2', 'heroe3', 'heroe4'];

    const sex: Sex[] = [Sex.male, Sex.female, Sex.male, Sex.female];

    const onVideoEnd = () => {
        
    }

    const moveToNext = () => {
        var element = document.getElementById("character");
        if (element) {
            element.classList.add('animate');
        }
        setTimeout(function(){
            onNext(images[selectedIndex], sex[selectedIndex], videos[selectedIndex])
        }, 300);
    }

    return (
        <div className="MainBG" id="character">
            <BackButton onClick={onBack}/>

            <div className='CharacterTitle' style={{top: topTitle}}>
                <ReactPlayer 
                    playing={true}
                    url={"videos/pick_heroe.mp4"}
                    width='100%'
                    height='95px'
                    loop={false}
                    muted={false}
                    onEnded={onVideoEnd}
                />
                <div className='CharacterHeroesContainer' style={{top: 30}}>
                    <div className='CharacterHeroes'>
                        <Hero 
                            name='Αλέκος' 
                            selectedIndex={selectedIndex} 
                            index={0} 
                            video={"videos/characters/heroe1.mp4"}
                            onClick={(index) => {setSelectedIndex(index); setMiddleOpacity(1); setEnableButton(true);}}
                            />
                        <Hero 
                            name='Χρύσα' 
                            selectedIndex={selectedIndex} 
                            index={1} 
                            video={"videos/characters/heroe2.mp4"}
                            onClick={(index) => {setSelectedIndex(index); setMiddleOpacity(1); setEnableButton(true);}}
                            />
                        <Hero 
                            name='Κώστας' 
                            selectedIndex={selectedIndex} 
                            index={2} 
                            video={"videos/characters/heroe3.mp4"}
                            onClick={(index) => {setSelectedIndex(index); setMiddleOpacity(1); setEnableButton(true);}}
                            />
                        <Hero 
                            name='Μαρία' 
                            selectedIndex={selectedIndex} 
                            index={3} 
                            video={"videos/characters/heroe4.mp4"}
                            onClick={(index) => {setSelectedIndex(index); setMiddleOpacity(1); setEnableButton(true);}}
                            />
                    </div>
                </div>
                <div className={enableButton ? "Play" : "PlayDisabled"} style={{top: 60, opacity: middleOpacity}}>
                    <ImageButton
                        image='images/pick.png'
                        onClick={enableButton ? moveToNext : undefined}
                    />
                </div>
            </div>
        </div>
    )
}