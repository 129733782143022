import React, { useEffect, useState, useContext } from 'react';
import { VerticallyAlignedDiv } from '../Components/VerticallyAlignedDiv';
import {QuestionInterface} from "./SceneInterfaces";
import { useSpring, animated } from 'react-spring';
import { ImageButton } from '../Components/ImageButton';
import './Questions.css';
import { ImageTextButton } from '../Components/ImageTextButton';
import { SizeContext } from '../../context';

interface QuestionProp {
    sceneId: number;
    items: Array<QuestionInterface>;
    onQuestionSelected: ()=>void;
    onSubmit: (index: number)=>void;
}

export const Questions: React.FC<QuestionProp> = ({sceneId, items, onQuestionSelected, onSubmit}:QuestionProp) => {
    const [selectedQuestion, setSelectedQuestion] = useState(-1);
    const [showToolTip, setShowToolTip] = useState(false);
    const [middleOpacity, setMiddleOpacity] = useState(0.3);

    const [questionWidth, setQuestionWidth] = useState('300px');
    const [questionHeight, setQuestionHeight] = useState('71px');
    const [questionInnerBoxWidth, setQuestionInnerBoxWidth] = useState('700px');
    const [questionBoxBottom, setQuestionBoxBottom] = useState('163px');
    const [submitMarginTop3, setSubmitMarginTop3] = useState('60px');
    const [submitMarginTop, setSubmitMarginTop] = useState('20px');

    const [questionOneBoxBottom, setQuestionOneBoxBottom] = useState('90px');
    const [questionOneWidth, setQuestionOneWidth] = useState('205px');
    const [questionOneHeight, setQuestionOneHeight] = useState('100px');
    const [questionOneBoxLeft, setQuestionOneBoxLeft] = useState('0px');

    const context = useContext(SizeContext);

    useEffect(() => {
        var timer: NodeJS.Timeout | undefined = undefined;
        if (items.length == 1) {
            setSelectedQuestion(0);
           /* timer = setTimeout(function(){
                setShowToolTip(true)
            }, items[0].delay ?? 1000 )*/
        } else {
            setSelectedQuestion(-1);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }
    }, [sceneId])

    useEffect(() => {
        var windowHeight = context.height;
        var windowWidth = context.width;

        var widthLimit = 1090 ;
        var heightLimit = widthLimit * (9 / 16);

        var _height = 71 * windowHeight / heightLimit;
        var _width = 300 * windowWidth / widthLimit;

        setQuestionHeight(_height + 'px');
        setQuestionWidth(_width +'px');

        var _innerWidth = 2*_width + 100;
        setQuestionInnerBoxWidth(_innerWidth + 'px');

        var _bottom = 163 * _height / 71;
        setQuestionBoxBottom(_bottom + 'px');

        var _marginTop3 = 60 * _height / 71;
        setSubmitMarginTop3(_marginTop3 + 'px');

        var _marginTop = 20 * _height / 71;
        setSubmitMarginTop(_marginTop + 'px');


        var oneBottom =  (items[0].bottom ? items[0].bottom : 80) * windowHeight / heightLimit;
        setQuestionOneBoxBottom(oneBottom + 'px');

        var _oneheight = 81 * windowHeight / 1080;
        var _onewidth = 166 * windowWidth / 1920;

        setQuestionOneHeight(_oneheight + 'px');
        setQuestionOneWidth(_onewidth +'px');

        var oneLeft =  (items[0].left ? items[0].left : 0)
        setQuestionOneBoxLeft(oneLeft + 'px');

    },[])

    const submitQuestion = () => {
        if (selectedQuestion !== -1) {
            onSubmit(selectedQuestion);
        }
    }

    const styles = useSpring({
        to: {marginLeft: 360 },
        from: {marginLeft: 4000}
        
    })

    const updateSelectedQuestion = (index: number) => {
        setSelectedQuestion(index);
        setMiddleOpacity(1);

        onQuestionSelected();
    }

    const oneQuestion = () => {
        return (
            <>
            <div className='QuestionBox'>
                <div className='QuestionInnerBox'>
                    <button className='QuestionSubmit1' onClick={submitQuestion}>
                        {items[0].text}
                    </button>
                 </div>
            </div>
            <div className='QuestionBox'>
                <div className='QuestionInnerBox'>
                    {showToolTip && 
                        <animated.div style={styles}>
                            <div className='QuestionDelay'>
                                <VerticallyAlignedDiv
                                    msg={"ΠΑΤΗΣΕ ΤΟ ΟΚ ΟΤΑΝ ΘΕΛΕΙΣ ΝΑ ΠΡΟΧΩΡΗΣΕΙ ΠΑΡΑΚΑΤΩ"}
                                />  
                            </div>
                        </animated.div>
                    }
                 </div>
            </div>
            </>
        )
    }

    const oneQuestionImage = () => {
        return (
            <div className='QuestionBox1' style={{bottom: questionOneBoxBottom, left: questionOneBoxLeft }}>
                <div className='QuestionInnerBox'>
                    <div className='QuestionSubmitImage1' style={{width: questionOneWidth, height: questionOneHeight}}>
                        <ImageButton
                            image={items[0].image!}
                            onClick={submitQuestion}
                        />
                    </div>
                </div>
            </div>
        )
    }
    
    const twoQuestions = () => {
        return (
            <div className='QuestionBox' style={{bottom: questionBoxBottom}}>
                <div className='QuestionInnerBox' style={{width: questionInnerBoxWidth}}>
                    <div className='Question' style={{width: questionWidth, height: questionHeight}}>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 0 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[0].text}
                                onClick={()=>updateSelectedQuestion(0)}
                            />
                        </div>
                        
                    </div>
                    <div className='QuestionSubmit' style={{opacity: middleOpacity, marginTop: submitMarginTop}}>
                        <ImageButton
                            image='images/middle.png'
                            onClick={submitQuestion}
                        />
                    </div>
                    <div className= 'Question' style={{width: questionWidth, height: questionHeight}}>
                    <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 1 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[1].text}
                                onClick={()=>updateSelectedQuestion(1)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const threeQuestions = () => {
        return (
            <div className='QuestionBox' style={{bottom: questionBoxBottom}}>
                <div className='QuestionInnerBox' style={{width: questionInnerBoxWidth}}>
                    <div className='Question' style={{width: questionWidth, height: questionHeight}}>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 0 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[0].text}
                                onClick={()=>updateSelectedQuestion(0)}
                            />
                        </div>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 1 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[1].text}
                                onClick={()=>updateSelectedQuestion(1)}
                            />
                        </div>
                        
                    </div>
                    <div className='QuestionSubmit3' style={{opacity: middleOpacity,  marginTop: submitMarginTop3}}>
                        <ImageButton
                            image='images/middle.png'
                            onClick={submitQuestion}
                        />
                    </div>
                    <div className= 'Question' style={{width: questionWidth, height: questionHeight}}>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 2 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[2].text}
                                onClick={()=>updateSelectedQuestion(2)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const fourQuestions = () => {
        return (
            <div className='QuestionBox' style={{bottom: questionBoxBottom}}>
                <div className='QuestionInnerBox' style={{width: questionInnerBoxWidth}}>
                    <div className='Question' style={{width: questionWidth, height: questionHeight}}>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 0 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[0].text}
                                onClick={()=>updateSelectedQuestion(0)}
                            />
                        </div>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 1 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[1].text}
                                onClick={()=>updateSelectedQuestion(1)}
                            />
                        </div>
                        
                    </div>
                    <div className='QuestionSubmit3' style={{opacity: middleOpacity,  marginTop: submitMarginTop3}}>
                        <ImageButton
                            image='images/middle.png'
                            onClick={submitQuestion}
                        />
                    </div>
                    <div className= 'Question' style={{width: questionWidth, height: questionHeight}}>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 2 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[2].text}
                                onClick={()=>updateSelectedQuestion(2)}
                            />
                        </div>
                        <div className='QuestionContainer'>
                            <ImageTextButton
                                image={selectedQuestion == 3 ? 'images/bub_selected_empty.png' : 'images/bub_empty.png'}
                                msg={items[3].text}
                                onClick={()=>updateSelectedQuestion(3)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {items.length == 1  && !items[0].image &&
                oneQuestion()
            }
            {items.length == 1  && items[0].image &&
                oneQuestionImage()
            }
            {items.length == 2  &&
            twoQuestions()
            }
            {items.length == 3  &&
            threeQuestions()
            }
            {items.length == 4  &&
            fourQuestions()
            }
        </>
       
    )
}