import React, { useEffect, useState, useContext } from 'react';

import ReactPlayer from 'react-player'
import { SizeContext } from '../../context';
import { Questions } from './Questions';
import {SCORM} from 'pipwerks-scorm-api-wrapper';

interface SceneResultsProps {
    results: string[];
    characterVideo: string;
    gameType: string;
    gameNumber: number;
}

export const SceneResults: React.FC<SceneResultsProps> = ({results, gameType, characterVideo, gameNumber}:SceneResultsProps) => {
    const [curentSceneId, setCurrentSceneId] = useState(0);
    const [displayQuestions, setDisplayQuestions] = useState(false)
    const [lastScene, setLastScene] = useState(false);

    const context = useContext(SizeContext);


    const getQuestion = () => {
        return {
            "id": 1,
            "text": "OK",
            "image": "images/buttons/katalava.png",
            "sceneId": 17,
            "delay": 2000,
            "audio": "",
            "bottom": 30
        };
    }

    const getVideoPath = (input: string) => {
        return "videos/game_" + gameNumber + "/" + gameType + "/" + characterVideo + "/" + input;
    }

    const onVideo1End = () => {
        setDisplayQuestions(true);
    }

    const onSubmit = (index: number) => {
        setDisplayQuestions(false);
        let nextId = curentSceneId + 1;
        setLastScene(nextId == (results.length - 1));
        if (nextId < results.length) {
            setCurrentSceneId(nextId);
        } 
    }

    const onFinished = () => {
        SCORM.set('cmi.core.lesson_status', 'completed');
        SCORM.save();
        SCORM.quit();
    }

    const onQuestionSelected = () => {
    }
    
    return (
        <>
        {!lastScene && 
            <>
                <ReactPlayer 
                    playing={true}
                    url={getVideoPath(results[curentSceneId])}
                    width={context.width + 'px'}
                    height={ context.height + 'px'}
                    muted={false}
                    onEnded={onVideo1End}
                />
                {displayQuestions && 
                    <Questions 
                        sceneId={curentSceneId}
                        items={[getQuestion()]}
                        onQuestionSelected={onQuestionSelected}
                        onSubmit={onSubmit}
                    />
                }
            </>
        }
        {lastScene && 
            <button className='ImageButton' onClick={onFinished}>
                <ReactPlayer 
                    playing={true}
                    url={getVideoPath(results[curentSceneId])}
                    width={context.width + 'px'}
                    height={ context.height + 'px'}
                    muted={false}
                    onEnded={onVideo1End}
                />
            </button>
        }
        </>
    )
}