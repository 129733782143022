import React, { useEffect, useState, useContext } from 'react';
import { ImageButton } from './ImageButton';
import { SizeContext } from '../../context';
import './ExitButton.css';

interface ExitButton {
    useDark?: boolean;
    onClick?: () => void;
}
export const ExitButton: React.FC<ExitButton> = ({useDark, onClick}: ExitButton) => {
    const [top, setTop] = useState('281px');
    const [left, setLeft] = useState('281px');
    const context = useContext(SizeContext);
    
    useEffect(()=> {
        var windowHeight = context.height; 
        var windowWidth = context.width; 
        var _top = 50 * windowHeight / 1080;
        setTop(_top + 'px');

        var _left = 1810 * windowWidth / 1920;
        setLeft(_left + 'px');
        
      },[])

    return ( 
        <div className='Exit' style={{top: top, left: left}}>
            <ImageButton
                image={useDark ? 'images/buttons/exit_dark.svg' : 'images/buttons/exit.svg'}
                onClick={onClick}
            />
        </div>
    );
}