import React from 'react';
import { ImageButtonProp } from './ImageButton';
import './ImageButton.css';
import { VerticallyAlignedDiv } from './VerticallyAlignedDiv';

interface ImageTextButtonProps extends ImageButtonProp {
    msg: string;
}
export const ImageTextButton: React.FC<ImageTextButtonProps> = ({msg, image, onClick}: ImageTextButtonProps) => {
    return ( 
        <button className='ImageButton' onClick={onClick}>
            <img src={image} alt='' style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}/> 
            <div className='ImageTextButton'>
                <VerticallyAlignedDiv
                    msg={msg}
                />
            </div>
        </button>
    );
}