import React, { useEffect, useState, useContext } from 'react';
import { SizeContext } from '../context';
import { ImageButton } from './Components/ImageButton';
import { VerticallyAlignedDiv } from './Components/VerticallyAlignedDiv';
import './StopGame.css';

interface StopGameProps{
    onExit: () => void;
    onCancel: () => void;
}

export const StopGame: React.FC<StopGameProps> = ({onExit, onCancel}: StopGameProps) => {
    
    const context = useContext(SizeContext);
    
    return (
        <div className="MainStopGameBG">
            <div className='StopGameTitle'>
                <VerticallyAlignedDiv
                    msg='ΠΡΟΕΙΔΟΠΟΙΗΣΗ!'
                />   
            </div>

            <div className='StopGameTitle'>
                <VerticallyAlignedDiv
                    msg='ΣΙΓΟΥΡΑ ΘΕΛΕΙΣ ΝΑ ΚΛΕΙΣΕΙΣ ΤΟ ΠΑΙΧΝΙΔΙ;'
                />   
            </div>
            <div className='StopGameSubTitle'>
                <VerticallyAlignedDiv
                    msg='Πρόσεξε ότι αν επιλέξεις να διακόψεις τώρα, <br> θα πρέπει μετά να ξεκινήσεις από την αρχή!'
                />   
            </div>

            <div className='StopGameButtons'>
                <ImageButton
                    image='images/buttons/continue_game.png'
                    onClick={onCancel}
                />
                    <ImageButton
                    image='images/buttons/close_game.png'
                    onClick={onExit}
                />
            </div>
        </div>
    )
}