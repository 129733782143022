import React, { useState, useEffect } from 'react';
import { ImageButton } from './Components/ImageButton';
import ReactPlayer from 'react-player'
import './Welcome.css';

interface WelcomeProps{
    onStart: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({onStart}: WelcomeProps) => {
    /*const [displayExit, setDisplayExit] = useState(false);

    const [displayRight2, setDisplayRight2] = useState(false);
    const [displayRight3, setDisplayRight3] = useState(false);
    const [displayRight4, setDisplayRight4] = useState(false);

    useEffect(() => {
        setTimeout(function(){
            setDisplayRight2(true);

            setTimeout(function(){
                setDisplayRight3(true);
                setDisplayExit(true)

                setTimeout(function(){
                    setDisplayRight4(true);
                }, 1000);

            }, 1000);

        }, 1000);

    },[]);*/

    const [showMain, setShowMain] = useState(true);
    const [showPame, setShowPame] = useState(false);

    const onVideoEnd = () => {
        setShowPame(true);
    }

    const onClick = () => {
        setShowMain(true)
    }

    return (
        <>
        {!showMain && 
            <button className='ImageButton' onClick={onClick}>
                <ReactPlayer 
                    playing={true}
                    url={"videos/first_screen.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={true}
                />
            </button>
        }
            {showMain && 
                <ReactPlayer 
                    playing={true}
                    url={"videos/a1.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    onEnded={onVideoEnd}
                />
            }
            {showPame && 
                <div className='Start'>
                    <ImageButton
                        image='images/pame.png'
                        onClick={onStart}
                    />
            </div>
            }
        </>
        );
    }
        {/*<div className="MainBG">
            <div className='Title'>
                <VerticallyAlignedDiv
                    msg='Τίτλος και logo παχνιδιού'
                />   
            </div>
            {displayExit &&
                <ExitButton onClick={()=>{}}/>
            }

            <div className='Left'>
                <b>Γιατί είμαι εδώ;</b>
                <p>
                    <span>
                        (α)τι είναι αυτό που θα ακολουθήσει;
                        (μια) διαδραστική ιστορία που σχετίζεται με
                        μια σειρά εκτάκτων καταστάσεων)
                    </span>
                    <br></br>
                    <span>
                        (β)γιατί;<br/>
                        (στο πλαισιο εκπαίδευσης γύρω από την
                        επιχειρησιακή συνέχεια)
                    </span>
                     <br></br>
                     <span>
                         (γ)ποιά η διάρκειά του;<br></br>
                         (20-30 λεπτά)
                     </span>
                </p>
            </div>

            <div className='Right'>
                <b>Τί πρέπει να κάνω;</b>
                <p>
                    <span>
                        (α)παρακολουθείς την ιστορία που εκτυλίσσεται
                    </span>
                    {displayRight2 && 
                        <br></br>
                    }
                    {displayRight2 && 
                        <span>
                            (β)ταυτίζεσαι με τον κεντρικό ήρωα
                        </span>
                    }
                    {displayRight2 && displayRight3 &&
                        <br></br>
                    }
                    {displayRight2 && displayRight3 &&
                        <span>
                             (γ)είσαι η φωνή και η σκέψη του ήρωα στους διαλόγους και αποφάσεις που παίρνει
                        </span>
                    }
                    {displayRight2 && displayRight3 && displayRight4 &&
                     <br></br>
                    }
                    {displayRight2 && displayRight3 && displayRight4 &&
                     <span>
                         (δ)μπορείς να πατήσεις το κουμπί εξόδου ανα πάσα στιγμή αν θέλεις να κλείσεις την εφαρμογή (θα πρέπει να ξεκινήσεις από την αρχόμως)
                     </span>
                    }
                </p>
            </div>

            <div className='Start'>
                <VerticallyAlignedButton
                    msg='Αρχίζουμε;'
                    onClick={onStart}
                />
            </div>
        </div>
        */}
