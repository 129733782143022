import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import './Hero.css';

interface HeroProps {
    name: string;
    selectedIndex: number;
    index: number;
    video: string;
    onClick: (index: number) => void;
}

export const Hero: React.FC<HeroProps> = ({name, selectedIndex, index, video, onClick}:HeroProps) => {
    const className = selectedIndex == index ? 'HeroSelected' : 'Hero'
    const classNameName = selectedIndex == index ? 'HeroNameSelected' : 'HeroName'

    return (
        <button className={className} onClick={()=>onClick(index)}>
            <div className='HeroImg'>
                <ReactPlayer 
                    playing={selectedIndex != index}
                    url={video}
                    width='100%'
                    height='100%'
                    loop={true}
                    muted={true}
                />
            </div>
        </button>
    )
}