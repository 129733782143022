import React, { useEffect, useState, useContext } from 'react';
import './VerticalTip.css';
import { useSpring, animated } from 'react-spring';
import { SizeContext } from '../../../context';

interface VerticalTipProps {
    name: string;
    bounce: boolean;
}

export const VerticalTip: React.FC<VerticalTipProps> = ({name, bounce}:VerticalTipProps) => {
    
    const context = useContext(SizeContext);
    
    const [props, set]  = useSpring(() => ({
        to: {bottom: 350 },
        from: {bottom: 1000}
        
    }))

    useEffect(() => {

        var windowHeight = context.height;

        var widthLimit = 1090 ;
        var heightLimit = widthLimit * (9 / 16);

        var bottom = 250 * windowHeight / heightLimit;
        var tipBounceBottom = 200 * windowHeight / heightLimit;

        if (!bounce) {
            set({
                to: {bottom: bottom },
                from: {bottom: 1000}
            });
        } else {
            set({
                to: {bottom: bottom },
                from: {bottom: tipBounceBottom}
            });
        }
    },[bounce]);

    return (   
        <animated.div className='VerticalBox' style={props}>
            <div className='VerticalTip'>
                <img src={'images/tip_bubble.png'} alt='' style={{ width: "100%", height: "auto", margin: 0, padding: 0 }}/> 
                <img src={'images/tip_triangle.png'} alt='' style={{ width: "60px", height: "auto", margin: 0, padding: 0 }}/> 
            </div>
        </animated.div>
    )
}