import React, { useEffect, useState, useContext } from 'react';
import FadeIn from 'react-fade-in';
import { ExitButton } from './Components/ExitButton';
import { BackButton } from './Components/BackButton';
import ReactPlayer from 'react-player'
import { ImageButton } from './Components/ImageButton';
import { SizeContext } from '../context';
import './Game.css';

interface GameProps{
    onExit: () => void;
    onBack: () => void;
    onNext: (type: string, game: number) => void;
}

export const Game: React.FC<GameProps> = ({onExit, onBack, onNext}: GameProps) => {
    const [showButtons, setShowButtons] = useState(false);
    const [selectedGame, setSelectedGame] = useState('');
    const [top, setTop] = useState('281px');

    const context = useContext(SizeContext);
    useEffect(()=> {
        var windowHeight = context.height;
        var _top = 381 * windowHeight / 1080;

        setTop(_top + 'px');
        
      },[])

    useEffect(()=> {
        setSelectedGame('');
    },[])
    
    const selectGame = (type: string) => {
        setSelectedGame(type);
    }

    const fadeInCompleted = () => {
        setTimeout(function(){
            onNext(selectedGame, 1);
        }, 700);
    }

    const onStartVideoEnd = () => {
        setShowButtons(true);
    }

    const onAtakaEnd = () => {
        setTimeout(function(){
            onNext(selectedGame, 1);
        }, 700);
    }


    return (
        <div className="MainBG">
            <BackButton onClick={onBack}/>

            <div className='GameTitle' style={{top: top}}>
                <ReactPlayer 
                    playing={true}
                    url={"videos/lets_start.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    onEnded={onStartVideoEnd}
                />
                {showButtons &&
                    <div className='GameTitleButtons'>
                        <ImageButton
                            image='images/network.png'
                            onClick={()=>selectGame("branch")}
                        />
                         <ImageButton
                            image='images/office.png'
                            onClick={()=>selectGame("main")}
                        />
                    </div>
                }
            </div>
            {selectedGame !== '' &&
                <div className='GameBottom'> 
                    <ReactPlayer 
                        playing={true}
                        url={"videos/ataka1.mp4"}
                        width='100%'
                        height='100%'
                        loop={false}
                        muted={false}
                        onEnded={onAtakaEnd}
                    />
                </div>
            }
        </div>
    )
}