import React, { useEffect, useState, useContext } from 'react';
import { ExitButton } from '../Components/ExitButton';
import { BackButton } from '../Components/BackButton';
import { Questions } from './Questions';
import {SceneInterface, ReponseInterface, QuestionInterface, DialogInterface, LezantaInterface} from "./SceneInterfaces";
import ReactAudioPlayer from 'react-audio-player';

import './Scene.css';
import { SceneBottom } from './SceneBottom';
import { VerticalTip } from './VerticalTip/VerticalTip';
import ReactPlayer from 'react-player'
import { SizeContext } from '../../context';
import { Lezanta } from './Lezanta';
import { Sex } from '../SexEnums';
import { SceneResults } from './SceneResults';
import {SCORM} from 'pipwerks-scorm-api-wrapper';

interface SceneProps {
    characterName: string;
    characterImage: string;
    characterSex: Sex;
    characterVideo: string;
    gameType: string;
    gameNumber: number;
    autoPlay: boolean;
    onBack: () => void;
}

export const Scene: React.FC<SceneProps> = ({characterName, characterImage, characterSex, characterVideo, gameType, gameNumber, autoPlay, onBack}:SceneProps) => {
    const [curentSceneId, setCurrentSceneId] = useState(1);
    const [scenes, setScenes] = useState<any>(undefined);
    const [scene, setScene] = useState<SceneInterface | undefined>(undefined);
    const [answer, setAnswer] = useState('');
    const [displayQuestions, setDisplayQuestions] = useState(false)
    const [videoIsFinished, setVideoIsFinished] = useState(false)
    const [videoPath, setVideoPath] = useState('');
    const [audioPath, setAudioPath] = useState('');

    const [bounceToolTip, setBounceToolTip] = useState(false);

    const [scene1, setScene1] = useState<SceneInterface | undefined>(undefined);
    const [scene2, setScene2] = useState<SceneInterface | undefined>(undefined);

    const [playScene1, setPlayScene1] = useState(false);
    const [playScene2, setPlayScene2] = useState(false);

    const [dialogName, setDialogName] = useState('');
    const [dialogText, setDialogText] = useState('');
    const [dialogImage, setDialogImage] = useState('');

    const [lezanta, setLezanta] = useState<LezantaInterface | undefined>(undefined);
    const [response, setResponse] = useState<ReponseInterface | undefined>(undefined);

    const [displayVideoResults, setDisplayVideoResults] = useState(false);

    const [videoResults, setVideoResults] = useState<string[]>([]);
    const [videoResultsSceneId, setVideoResultsSceneId] = useState<number[]>([]);

    const [questionIndex, setQuestionIndex] = useState<number | undefined>(undefined);
    const [responseIndex, setResponseIndex] = useState<number | undefined>(undefined);

    const [correctAnswers, setCorrectAnswers] = useState<number[]>([]);
    const [wrongAnswers, setWrongAnswers] = useState<number[]>([]);
    const [displayedDialog, setDisplayedDialog] = useState<DialogInterface | undefined>(undefined);

    const context = useContext(SizeContext);

    const responseDelay = 3000;
    const questionDelay = 3000;
    const videoDelay = 3000;

    var showToolTipTimer: any = undefined ;

    // temp
    /*useEffect(() => {
        var i,j;
        var _videoResults: string[] = [];
        for (i=0; i<scenes.length; i++) {
            let scene = scenes[i];

            if (scene.questions) {
                let questions = scene.questions;
                for (j=0;j<questions.length; j++){ 
                    let question = questions[j];
                    if (question.videoResult) {
                        _videoResults.push(question.videoResult)
                    }
                }
            }
        }
        setVideoResults(_videoResults);
    },[])*/

    useEffect(() => {
        if (gameType == "branch") {
            setVideoResults(oldArray => [...oldArray, "DKT_D0.mp4"]);
        } else {
            setVideoResults(oldArray => [...oldArray, "D0.mp4"]);
        }

        setVideoResultsSceneId(oldArray => [...oldArray, -1]);
    },[])

    useEffect(() => {
        SCORM.set('cmi.core.score.min', 0);
        SCORM.save();

        fetch('scenes/game_' + gameNumber + '/' + gameType + '/scenes.json').then(
            function(res){
            return res.json()
          }).then(function(data){
            setScenes(data);
          }).catch(
            function(err){
              console.log(err, ' error')
            }
          )
    },[])

    useEffect(() => {
        var score = correctAnswers.length;
        var total = score + wrongAnswers.length;
        SCORM.set('cmi.core.score.max', total);
        SCORM.set('cmi.core.score.raw', score);
        SCORM.save();

        if (score > 0 || total > 0) {
            localStorage.setItem("correct", JSON.stringify(correctAnswers));
            localStorage.setItem("wrong", JSON.stringify(wrongAnswers));
        }
    },[correctAnswers, wrongAnswers])

    useEffect(() => {
        if (scenes) {
            loadScene();
        }
    },[curentSceneId, scenes])

    useEffect(() => {
        console.table(videoResults);
    },[videoResults])
    
    
    useEffect(() => {
        reset();
         
        //remove this 
        //setVideoPath('dummy');
        /*setTimeout(function(){
            setVideoPath('');
        }, 100);*/
    },[scene])

    useEffect(() => {
        setCurrentSceneId(JSON.parse(localStorage.getItem("scene") ?? "1"));
        
        if (localStorage.getItem("correct") != null) {
            setCorrectAnswers(JSON.parse(localStorage.getItem("correct") ?? ""));
        }
        if (localStorage.getItem("wrong") != null) {
            setWrongAnswers(JSON.parse(localStorage.getItem("wrong") ?? ""));
        }

    },[])

    const reset = () => {
        setAnswer("");
        setVideoIsFinished(false);
        setDisplayQuestions(false);
        
        setLezanta(undefined);
        setDialogName("");
        setDialogImage("");
        setDialogText("");
        setResponse(undefined);
        setBounceToolTip(false);
    }

    const loadScene = () => {
        if (!scenes) {
            lastScene();
            return
        }

        var _scene = scenes.find((scene:SceneInterface) => scene.id === curentSceneId);
        if (_scene) {
            reset();

            setScene(_scene);
            setPlayScene1(true);
            setScene1(_scene);

            setScene2(undefined);
            setPlayScene2(false);

            setScene2(findNextScene(_scene));

            localStorage.setItem("scene", JSON.stringify(curentSceneId));
            
        } else {
            localStorage.clear();
            lastScene();
        }   
        
    }

    const onSubmit = (index: number) => {
        setQuestionIndex(index);
        setResponseIndex(undefined);

        var question = scene!.questions![index];
        if (question.text !== "OK") {
            setAnswer(question.text);
        }

        if (scene!.questions!.length > 1) {
            setCorrectAnswers(oldArray => oldArray.filter(item => item != scene!.id))
            setWrongAnswers(oldArray => oldArray.filter(item => item != scene!.id))
            
            if (question.correct) {
                setCorrectAnswers(oldArray => [...oldArray, scene!.id]);
            } else {
                setWrongAnswers(oldArray => [...oldArray, scene!.id]);
            }
        }

        if (question.audio) {
            setAudioPath(question.audio);
        }

        if (question.videoResult) {
            if (!videoResultsSceneId.includes(scene!.id)) {
                setVideoResults(oldArray => [...oldArray, question.videoResult!]);
                setVideoResultsSceneId(oldArray => [...oldArray, scene!.id]);
            } else {
                var _temp: string[] = [];
                var i;
                for (i = 0; i< videoResults.length - 1; i++){
                    _temp.push(videoResults[i]);
                }
                setVideoResults([..._temp, question.videoResult!]);
            }
        }

        if (showToolTipTimer != undefined) {
            clearTimeout(showToolTipTimer);
        }

        setDisplayQuestions(false);
        
        if (!question.audio) {
         //   setTimeout(function(){
                if (scene?.response) {
                    setResponse(scene?.response);
                    setTimeout(function(){
                        moveToNextScene(index);
                    }, responseDelay);
                } else if (question.responses){
                    parseQuestionResponse(question, 0);
                } else {
                    moveToNextScene(index);
                }
          //  }, questionDelay)
        }
    }

    const onQuestionSelected = () => {
        showToolTipTimer =  setTimeout(function(){
            setBounceToolTip(true);
        }, 2000);
    }

    const parseQuestionResponse = (question: QuestionInterface ,_reqponseIndex: number) => {
        var _responses = question.responses!;
        if (_responses.length > _reqponseIndex) {
            var _response = _responses[_reqponseIndex];
            setAnswer('');
            setResponse(_response);

            setResponseIndex(_reqponseIndex);

            if (_response.audio) {
                setAudioPath(_response.audio);
            } else {
                setTimeout(function() {
                    var nextResponse = _reqponseIndex + 1;
                    parseQuestionResponse(question, nextResponse);
                }, _response.timeout ? _response.timeout : responseDelay);
    
            }
        } else {
            setResponse(undefined);
            setTimeout(function(){
                moveToNextScene(questionIndex!);
                setQuestionIndex(undefined);
                setResponseIndex(undefined);
            }, responseDelay);
        }
    } 

    const moveToNextScene = (index: number) => {
        var question = scene!.questions![index];
        var id = question.sceneId;
        if (curentSceneId != id) {
            setCurrentSceneId(id);
        } else {
            loadScene();
        }
    }

    const onVideo1End = () => {
        if (scene2) {
            setScene1(undefined);
            setPlayScene1(false);
            setPlayScene2(true);
            setScene(scene2);

            setScene1(findNextScene(scene2));
            
            return;
        }
        moveToNextVideo();
    }

    const onVideo2End = () => {
        if (scene1) {
            setScene2(undefined);
            setPlayScene2(false);
            setPlayScene1(true);
            setScene(scene1);

            setScene2(findNextScene(scene1));
        
            return
        }
        moveToNextVideo()
    }

    const moveToNextVideo = () => {
        setVideoIsFinished(true)
        if (scene?.questions) {
           // parseDialog(undefined); //reset it
           // setResponse(undefined); //reset it
            setDisplayQuestions(true);
        } else {
            setTimeout(function(){
                if (scene?.nextId) {
                    var id = scene.nextId
                    setCurrentSceneId(id);    
                } else {
                    setCurrentSceneId(curentSceneId + 1);
                }
            }, videoDelay);
        }
    }

    const findNextScene = (testScene: SceneInterface) => {
        if (!testScene.questions) {
            var id = curentSceneId + 1;
            if(testScene.nextId) {
                id = testScene.nextId;
            }
            var _scene  = scenes.find((scene:SceneInterface) => scene.id === id);
            return  _scene;
        } 
        else {
            return undefined;
        }
    }

    const onBackButton = () => {
        if (scene && scene.backId) {
            reset();
            var id = scene!.backId;
            if (id != scene.id) {
                if (curentSceneId != id) {
                    setCurrentSceneId(id);
                } else {
                    loadScene();
                }
            } else {
                setVideoPath('dummy');
                setTimeout(function(){
                    setVideoPath('');
                }, 100);
                setCurrentSceneId(id);
            }
        }
    }

    const lastScene = () => {
        //alert("last scene");

        if (gameType == "branch") {
            setVideoResults(oldArray => [...oldArray, "DKT_D_end.mp4"]);
        } else {
            setVideoResults(oldArray => [...oldArray, "D_end.mp4"]);
        }

        reset();
        setPlayScene1(false);
        setPlayScene2(false);
        setDisplayVideoResults(true);
    }

    const onProgress1 = (playedSeconds: number) => {
       // console.log(playedSeconds);
        if (scene1?.dialog && playScene1) {
            var i;
            var foundDialog: DialogInterface|undefined = undefined
            for (i = 0; i< scene1.dialog.length; i++) {
                var dialog = scene1?.dialog[i];
                var start = dialog.start
                var end = dialog.end ?? 1000000

                if (playedSeconds >= start && playedSeconds < end) {
                    foundDialog = dialog
                    break;
                }
            }

            parseDialog(foundDialog);
        }

        if (scene1?.lezanta && playScene1) {
            parseLezanta(scene1, playedSeconds);
        }
    }

    const onProgress2 = (playedSeconds: number) => {
    //    console.log(playedSeconds);
        if (scene2?.dialog && playScene2) {
            var i;
            var foundDialog: DialogInterface|undefined = undefined
            for (i = 0; i< scene2.dialog.length; i++) {
                var dialog = scene2?.dialog[i];
                var start = dialog.start
                var end = dialog.end ?? 1000000

                if (playedSeconds >= start && playedSeconds < end) {
                    foundDialog = dialog
                    break;
                }
            }

           parseDialog(foundDialog);
        }

        if (scene2?.lezanta && playScene2) {
            parseLezanta(scene2, playedSeconds);
        }
    }

    const parseDialog = (dialog: DialogInterface | undefined) => {
        if (displayedDialog != dialog) {
            setDialogName("");
            setDialogImage("");
            setDialogText("");
        }
        if (dialog) {
            setDialogName(dialog.name ?? characterName);
            setDialogImage(dialog.image ?? characterImage);
            setDialogText(dialog.text.replace("@@", characterName))
        }

        setDisplayedDialog(dialog);
    }

    const parseLezanta = (_scene: SceneInterface, playedSeconds: number) => {
        if (_scene.lezanta) {
            var i;
            var foundLezanta: LezantaInterface|undefined = undefined
            for (i = 0; i< _scene.lezanta.length; i++) {
                var _lezanta = _scene?.lezanta[i];
                var start = _lezanta.start
                var end = _lezanta.end ?? 1000000

                if (playedSeconds >= start && playedSeconds < end) {
                    foundLezanta = _lezanta
                    break;
                }
            }
            
            setLezanta(foundLezanta)
        }
    }

    const getVideoPath = (input: string) => {
        return "videos/game_" + gameNumber + "/" + gameType + "/" + characterVideo + "/" + input;
    }

    const getAudioPath = (input: string) => {
        return "audio/game_" + gameNumber + "/" + gameType + "/" + characterVideo + "/" + input;
    }

    const onAudioEnd = () => {
        setAudioPath('');

        if (questionIndex || questionIndex == 0) {
            if (responseIndex || responseIndex == 0) {
                var question = scene!.questions![questionIndex];
                var nextResponse = responseIndex + 1;
                parseQuestionResponse(question, nextResponse);
            } else {
                var question = scene!.questions![questionIndex];
                if (scene?.response) {
                    setResponse(scene?.response);
                    setTimeout(function(){
                        moveToNextScene(questionIndex);
                    }, responseDelay);
                } else if (question.responses){
                    parseQuestionResponse(question, 0);
                } else {
                    moveToNextScene(questionIndex);
                }
            }
        }
    }

    return (
        <div className="MainBG">
        {scene && 
            <>
                {/*<div className='Title'>
                    <VerticallyAlignedDiv
                    msg={scene.name}
                    />   
        </div>*/}
                
                {scene1 && scene1.videoPath && 
                    <ReactPlayer 
                        playing={playScene1 && autoPlay && !videoIsFinished}
                        url={videoPath !== '' ? videoPath : getVideoPath(scene1.videoPath)}
                        width={playScene1 ? context.width : 0 + 'px'}
                        height={playScene1 ? context.height : 0 + 'px'}
                        muted={false}
                        onEnded={onVideo1End}
                        onProgress={(e) => onProgress1(e.playedSeconds)}
                    />
                }

                {scene2 && scene2.videoPath && 
                    <ReactPlayer 
                        playing={playScene2 && autoPlay && !videoIsFinished}
                        url={videoPath !== '' ? videoPath : getVideoPath(scene2.videoPath)}
                        width={playScene2 ? context.width : 0 + 'px'}
                        height={playScene2 ? context.height : 0 + 'px'}
                        muted={false}
                        onEnded={onVideo2End}
                        onProgress={(e) => onProgress2(e.playedSeconds)}
                    />
                }

                {scene.questions && displayQuestions && 
                    <Questions 
                        sceneId={scene.id}
                        items={scene.questions}
                        onQuestionSelected={onQuestionSelected}
                        onSubmit={onSubmit}
                    />
                }

                {scene.tip && displayQuestions &&
                    <VerticalTip 
                    name={scene.tip}
                    bounce={bounceToolTip}
                    />
                }

                {videoIsFinished && answer && 
                    <SceneBottom 
                        name={characterName}
                        answer={answer}
                        image={characterImage}
                    />
                }

                {dialogName && dialogText && !answer && !response &&
                    <SceneBottom 
                        name={dialogName}
                        answer={dialogText}
                        image={dialogImage ? dialogImage : characterImage }
                    />
                }

                {response &&
                    <SceneBottom 
                        name={response.name ? response.name : characterName}
                        answer={response.text}
                        image={response.image ? response.image : characterImage }
                    />
                }
                {lezanta && 
                    < Lezanta
                        item={lezanta}
                        characterName={characterName}
                        characterSex={characterSex}
                    />
                }
                {scene.backId && !displayVideoResults &&
                    <BackButton onClick={onBackButton} useDark={true}/>
                }
                {displayVideoResults &&
                    <SceneResults 
                        gameType={gameType}
                        characterVideo={characterVideo}
                        results={videoResults}
                        gameNumber={gameNumber}
                    />
                }
                {audioPath && 
                    <ReactAudioPlayer
                        src={getAudioPath(audioPath)}
                        autoPlay
                        onEnded={onAudioEnd}
                    />
                }
            </>
        }
            <ExitButton onClick={onBack} useDark={true}/>
        </div>
    )
}