import React, { useEffect, useState, useContext } from 'react';
import { ImageButton } from './ImageButton';
import { SizeContext } from '../../context';
import './BackButton.css';

interface BackButton {
    useDark?: boolean;
    onClick?: () => void;
}
export const BackButton: React.FC<BackButton> = ({useDark, onClick}: BackButton) => {
    const [top, setTop] = useState('281px');
    const [left, setLeft] = useState('281px');

    const context = useContext(SizeContext);
    useEffect(()=> {
        var windowHeight = context.height; 
        var windowWidth = context.width; 
        var _top = 50 * windowHeight / 1080;
        setTop(_top + 'px');

        var _left = 50 * windowWidth / 1920;
        setLeft(_left + 'px');
        
      },[])

    return ( 
        <div className='Back' style={{top: top, left: left}}>
            <ImageButton
                image={useDark ? 'images/buttons/back_dark.svg' : 'images/buttons/back.svg'}
                onClick={onClick}
            />
        </div>
    );
}