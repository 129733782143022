import React from 'react';
import './VerticallyAlignedDiv.css';

export interface VerticallyAlignedDivProps {
    msg: string;
}
export const VerticallyAlignedDiv: React.FC<VerticallyAlignedDivProps> = ({msg}: VerticallyAlignedDivProps) => {
    return (
        <div className='VerticalAlignDiv'>
            <p dangerouslySetInnerHTML={{ __html: msg }}>
            </p>
        </div>
    );
}